import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { Icon } from 'assets/icons';
import { useUserService } from 'hooks/iam/user';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addDataToStore } from 'store/actions/user-data';

export const AffiliateProfileDetails = () => {
  const dispatch = useDispatch();
  const user_data = useSelector((store) => store.user_data);
  const {
    email,
    firstname,
    lastname,
    referral: { code, percentage }
  } = user_data;

  const { updateUserData } = useUserService();

  const [form_data, setFormData] = useState({});
  const [mode, setMode] = useState('view');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const {
      email,
      firstname,
      lastname,
      referral: { code }
    } = user_data;
    setFormData({ email, firstname, lastname, code });
  }, [user_data.id]);

  const handleInputChange = (property = '', value = '') => {
    if (!property || !value) return;
    setFormData((curr_data) => ({ ...curr_data, [property]: value }));
  };

  const submit = async () => {
    const data = {
      ...form_data,
      old_code: code,
      referral: { ...user_data.referral, code: form_data.code }
    };
    setSaving(true);
    const result = await updateUserData(user_data.id, { data });
    if (result) {
      toast.success('Update successful');
      dispatch(addDataToStore({ ...user_data, ...data }));
      setMode('view');
    }
    setSaving(false);
  };

  return (
    <>
      <BasicInfoSection
        title="Basic details"
        icon={<Icon name="edit" onClick={() => setMode('edit')} />}
      >
        {mode === 'view' ? (
          <>
            <DetailItem title="First name">{firstname}</DetailItem>
            <DetailItem title="last name">{lastname}</DetailItem>
            <DetailItem title="Email address">{email}</DetailItem>
            <DetailItem title="Referral code">{code}</DetailItem>
            <DetailItem title="Earning Percentage">{percentage}</DetailItem>
          </>
        ) : (
          <>
            <DetailItem title="First name">
              <SimpleInput
                value={form_data.firstname}
                onInput={(v) => handleInputChange('firstname', v)}
              />
            </DetailItem>
            <DetailItem title="last name">
              <SimpleInput
                value={form_data.lastname}
                onInput={(v) => handleInputChange('lastname', v)}
              />
            </DetailItem>
            <DetailItem title="Email address">
              <SimpleInput value={form_data.email} onInput={(v) => handleInputChange('email', v)} />
            </DetailItem>
            <DetailItem title="Referral code">
              <SimpleInput value={form_data.code} onInput={(v) => handleInputChange('code', v)} />
            </DetailItem>
            <DetailItem title="Earning Percentage">{percentage}</DetailItem>
            <Spacer multiple={4} />
            <Button text="Save Changes" loading={saving} onClick={submit} />
            <Spacer multiple={4} />
            <Button text="Cancel" type="secondary" onClick={() => setMode('view')} />
          </>
        )}
      </BasicInfoSection>
    </>
  );
};
