/** */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { prepareCheckoutConfig } from './helper';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { InfoBanner } from 'app/shared/info-banner';

import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import useValidator from 'hooks/validator';
import { refillWalletValidationConfig } from 'app/shared/utils/form/validation/wallet';
import { useTenantService } from 'hooks/iam/tenant';
import { useTaxService } from 'hooks/sales/tax';
import { useCurrencyService } from 'hooks/currency';
import styles from './wallet.module.css';
import { Spacer } from 'app/layouts/generic';
import { toReadableNumber } from 'app/shared/utils/number';
import { DatePicker } from '@arco-design/web-react';
import { Checkbox } from 'app/shared/input/checkbox';
import { AutoTopUpConfig } from './auto-topup';
import { useWalletService } from 'hooks/sales/wallet';
import { usePaymentService } from 'hooks/sales/payment';

const TopupForm = ({ closeModal }) => {
  const { tenant_id } = useSelector((state) => state.user_data);
  const wallet_in_store = useSelector((state) => state.wallet);
  const { fetchCurrencies } = useCurrencyService();
  const { createPayment } = usePaymentService();
  const { fetchTenant } = useTenantService();
  const { fetchTaxes } = useTaxService();
  const { determineTopUpCostSummary, fetchWallet, updateWallet } = useWalletService();
  const { errors, validateField, form_is_valid } = useValidator(refillWalletValidationConfig);

  const [amount, setAmount] = useState(0);
  const [auto_topup, setAutoTopUp] = useState({});
  const [credits_to_buy, setCreditsToBuy] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [expiration_date, setExpirationDate] = useState('');
  const [selected_currency, setSelectedCurrency] = useState({});
  const [show_autotopup_form, setShowAutoTopUpForm] = useState(false);
  const [tax, setTax] = useState(0);
  const [taxes, setTaxes] = useState([]);
  const [tax_rate, setTaxRate] = useState(0);
  const [tenant, setTenant] = useState({});
  const [virtual_account, setVirtualAccount] = useState(null);

  useEffect(() => {
    Promise.all([fetchCurrencies(), fetchTenant(tenant_id), fetchTaxes(), fetchWallet()]).then(
      ([{ currencies }, { tenant }, { taxes }]) => {
        const mapped_currencies = (currencies || []).map((curr) => {
          return {
            ...curr,
            label: curr.code,
            value: curr.code
          };
        });
        const currency = mapped_currencies.find((option) => option.value === 'NGN');
        const { tax: tx_rate } = taxes.find((tax) => tax.currency === 'NGN') || { tax: 0 };

        /** */
        setCurrencies(mapped_currencies);
        setSelectedCurrency(currency);
        setTaxes(taxes || []);
        setTaxRate(tx_rate);
        setTenant(() => tenant);
      }
    );
  }, [tenant_id]);

  useEffect(() => {
    validateField('credits_to_buy', credits_to_buy);
    validateField('expiration_date', expiration_date);
    validateField('currency', selected_currency);

    const { tax: tx_rate } = taxes.find((tax) => tax.currency === selected_currency.value) || {
      tax: 0
    };

    //
    const { amount, tax } = determineTopUpCostSummary({
      credits_to_buy,
      currency: selected_currency.value,
      tax_rate: tx_rate,
      expiration_date,
      wallet_rate: wallet_in_store.rate
    });

    setAmount(amount);
    setTax(tax);
    setTaxRate(tx_rate);
  }, [selected_currency?.value, credits_to_buy, expiration_date]);

  useMemo(() => {
    const { virtual_account: v_account, auto_topup } = wallet_in_store;
    const account = v_account && v_account.account_number ? v_account : null;
    setVirtualAccount(account);
    setAutoTopUp(() => auto_topup || {});
    setShowAutoTopUpForm(auto_topup?.is_enabled);
  }, [wallet_in_store.tenant_id]);

  const generateDate = (months = 1) => {
    const date = new Date();
    const end_date_timestamp = date.setMonth(date.getMonth() + months);
    const [end_date] = new Date(end_date_timestamp).toISOString().split('T');

    return end_date;
  };

  const handleAutoTopUpConfigChange = (key = '', value) => {
    if (!key) return;
    setAutoTopUp((config) => ({ ...config, [key]: value }));
  };

  const submit = async () => {
    const int_amount = Number(amount);
    if (int_amount <= 0) {
      toast.error('Enter a valid amount');
      return;
    }

    if (!tenant || !tenant.id) {
      return toast.error('Unable to load data. Please reload browser.');
    }

    const total_cost = Number(amount) + Number(tax);
    const payment_options = show_autotopup_form ? 'card' : '';
    const config = prepareCheckoutConfig(tenant, total_cost, selected_currency, payment_options);
    const data = {
      amount,
      currency: selected_currency.value,
      conversion_rate: selected_currency.exchange_rate,
      email_credits: credits_to_buy,
      expiration_timestamp: expiration_date ? Date.parse(expiration_date) : generateDate(3),
      tenant_id: tenant.id,
      tx_ref: config.tx_ref
    };
    const result = await createPayment({ data });

    if (result) {
      if (show_autotopup_form) {
        await updateWallet({
          ...wallet_in_store,
          auto_topup: {
            ...auto_topup,
            currency: selected_currency.value,
            is_enabled: show_autotopup_form
          },
          tenant_id
        });
      }
      window.FlutterwaveCheckout(config);
      closeModal();
    }
  };

  return (
    <>
      <div>
        <GridRow num_of_columns={4}>
          <GridColumn span={3} />
          <GridColumn>
            <DetailItem>
              <MultiSelect
                className="gm-input"
                options={currencies}
                onChange={setSelectedCurrency}
                sort_options_by="source"
                value={selected_currency}
                error={errors.currency}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
        {virtual_account && (
          <GridRow num_of_columns={2}>
            <GridColumn span={2}>
              <div style={{ marginBottom: '20px' }}>
                <InfoBanner
                  message={`You can top up your wallet (Naira only) by making a bank transfer to your virtual account:
              
                  Account Name: ${virtual_account.account_name}
                  Account Number: ${virtual_account.account_number}
                  Bank Name: ${virtual_account.bank_name}`}
                />
              </div>
            </GridColumn>
          </GridRow>
        )}
        <div className={styles.details}>
          <GridRow num_of_columns={2}>
            <GridColumn>Cost</GridColumn>
            <GridColumn>
              <div style={{ textAlign: 'right' }}>{toReadableNumber(amount)}</div>
            </GridColumn>
          </GridRow>
          <GridRow num_of_columns={2}>
            <GridColumn>VAT({tax_rate}%)</GridColumn>
            <GridColumn>
              <div style={{ textAlign: 'right' }}>{toReadableNumber(tax)}</div>
            </GridColumn>
          </GridRow>
          <Spacer multiple={2} />
          <GridRow num_of_columns={2}>
            <GridColumn>Expiration date</GridColumn>
            <GridColumn>
              <div style={{ textAlign: 'right' }}>{expiration_date}</div>
            </GridColumn>
          </GridRow>
          <Spacer multiple={4} />
          <b>
            <GridRow num_of_columns={2}>
              <GridColumn>Total</GridColumn>
              <GridColumn>
                <div style={{ textAlign: 'right' }}>{toReadableNumber(tax + amount)}</div>
              </GridColumn>
            </GridRow>
          </b>
        </div>
        <Spacer multiple={4} />
        <GridRow num_of_columns={2}>
          <GridColumn>
            <DetailItem title="Number of credits">
              <SimpleInput
                className="gm-input"
                type="number"
                value={credits_to_buy}
                onInput={setCreditsToBuy}
                error={errors.credits_to_buy}
              />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Expiration Date">
              <DatePicker
                className={styles.datepicker}
                onChange={setExpirationDate}
                value={expiration_date}
              />
              <span className={styles.error}>{errors.expiration_date}</span>
            </DetailItem>
          </GridColumn>
        </GridRow>
        <GridRow num_of_columns={1}>
          <GridColumn>
            <Checkbox
              label="Enable auto debit"
              input_id="auto_topup"
              checked={show_autotopup_form}
              onClick={() => setShowAutoTopUpForm((value) => !value)}
            />
          </GridColumn>
        </GridRow>
        <Spacer multiple={4} />
        {show_autotopup_form && (
          <AutoTopUpConfig config={auto_topup} onChange={handleAutoTopUpConfigChange} />
        )}
        <GridRow num_of_columns={2}>
          <GridColumn />
          <GridColumn>
            <Button
              text={`Pay ${toReadableNumber(amount + tax)}`}
              onClick={submit}
              disabled={!form_is_valid}
            />
          </GridColumn>
        </GridRow>
      </div>
    </>
  );
};

export default TopupForm;
