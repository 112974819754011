import { useSelector } from 'react-redux';
import * as ReferralService from 'services/referrals';

export const useReferralService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchReferrals = async (options = {}) => {
    const { error, payload } = await ReferralService.read({ ...options, token });
    if (error) {
      return { referrals: [], size: 0, error: 'Unable to fetch referrals at this time.' };
    }

    const {
      data: referrals,
      meta: { size }
    } = payload;
    return { referrals, size, error: null };
  };

  return {
    fetchReferrals
  };
};
