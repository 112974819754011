import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { Icon, SiteIcon } from 'assets/icons';
import styles from './header.module.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGuestService } from 'hooks/guest';
import { logout } from 'store/actions/user-data';
import { useDispatch } from 'react-redux';
import useScreenSize from 'hooks/size';

export const AffiliateHeader = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { isTablet } = useScreenSize();

  const { logOut } = useGuestService();

  const handleLogout = async () => {
    navigateTo('/login');
    await logOut();
    dispatch(logout({}));
  };

  return (
    <div className={styles.header}>
      <GridRow num_of_columns={isTablet ? 6 : 10}>
        {!isTablet && <GridColumn span={2} />}
        <GridColumn span={6}>
          <div className={styles.contentArea}>
            <div className={styles.siteAssetWrapper}>
              <SiteIcon />
              <span className={styles.siteName}>
                Go-Mailer<span className={styles.orangeDot}>.</span>
              </span>
            </div>
            <div className={styles.actionWrapper}>
              <Button
                className={styles.btn}
                text="Create Organisation"
                onClick={() => navigateTo('/organizations')}
              />
              <Icon className={styles.logoutBtn} name="logout" onClick={handleLogout} />
            </div>
          </div>
        </GridColumn>
        {!isTablet && <GridColumn span={2} />}
      </GridRow>
    </div>
  );
};
