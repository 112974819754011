import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { Routes, Route } from 'react-router-dom';
import { SecuritySetting } from './security';
import { ProfileSettings } from './profile';
import CreateContactMapper from './files/contacts';
import DragDropFile from './email/form';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { Spacer } from 'app/layouts/generic';
import { Billing } from './billing';
import { EmailSettings } from './email';
import { DeveloperSetting } from './developer';
import { PermissionsContext } from 'contexts/permissions';
import { RegularUserAffiliateModule } from './affiliate';

const SettingsModule = () => {
  const dispatch = useDispatch();
  const { contact_upload, email_footnote, billing } = useSelector((state) => state.flags);
  const { permissions } = useContext(PermissionsContext);

  const NAVITEMS = {
    profile: {
      index: 1,
      title: 'Profile',
      path: '/settings/profile'
    },
    affiliate: {
      index: 1,
      title: 'Affiliate Program',
      path: '/settings/affiliates'
    },
    security: {
      index: 5,
      title: 'Security',
      path: '/settings/security'
    },
    billing: {
      index: 6,
      title: 'Billing',
      path: '/settings/billing',
      hidden: !billing?.is_permitted || !permissions['billing_config:view']
    },
    email: {
      index: 4,
      title: 'Email',
      path: '/settings/email',
      hidden: !permissions['email_config:view']
    },
    developer: {
      index: 6,
      title: 'Developer',
      path: '/settings/developer',
      hidden: !permissions['developer_config:view']
    },
    contact_upload: {
      index: 2,
      title: 'File configuration',
      path: '/settings/files',
      hidden: !permissions['file_config:view'] || !contact_upload?.is_permitted
    }
  };

  const [nav_items] = useState(NAVITEMS);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Settings', path: '.' }]));
  }, [dispatch]);

  return (
    <PageContentWrapper>
      <header>
        <SlidingNav nav_items={Object.values(nav_items).sort((a, b) => a.index - b.index)} />
      </header>
      <Spacer multiple={6} />
      <Routes>
        <Route path="profile" element={<ProfileSettings />} />
        <Route path="affiliates" element={<RegularUserAffiliateModule />} />
        {email_footnote?.is_permitted && <Route path="footnote" element={<DragDropFile />} />}
        {contact_upload?.is_permitted && permissions['file_config:view'] && (
          <Route path="files" element={<CreateContactMapper />} />
        )}
        {permissions['email_config:view'] && <Route path="/email" element={<EmailSettings />} />}
        <Route path="/security" element={<SecuritySetting />} />
        {permissions['developer_config:view'] && (
          <Route path="/developer" element={<DeveloperSetting />} />
        )}
        {billing?.is_permitted && permissions['billing_config:view'] && (
          <Route path="/billing" element={<Billing />} />
        )}
        <Route path="/*" element={<ProfileSettings />} />
      </Routes>
    </PageContentWrapper>
  );
};

export default SettingsModule;
