export const prepareFreemiumMessage = (module) => {
  switch (module.code) {
    case 'campaign':
      return 'Send up to 4 campaigns per month to all your contacts for FREE.';
    case 'automation':
      return 'Process up to 200 automation journey points per month for FREE.';
    default:
      return '';
  }
};
