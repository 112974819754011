import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { Icon } from 'assets/icons';
import { useUserService } from 'hooks/iam/user';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addDataToStore } from 'store/actions/user-data';

export const AffiliateAccountDetails = () => {
  const dispatch = useDispatch();
  const user_data = useSelector((store) => store.user_data);
  const { payout_account } = user_data;

  const { updateUserData } = useUserService();

  const [account_details, setAccountPDetails] = useState({});
  const [form_data, setFormData] = useState({});
  const [mode, setMode] = useState('view');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setFormData(payout_account || {});
  }, [payout_account]);

  useEffect(() => {
    if (!payout_account || !Object.keys(payout_account).length) return;
    setAccountPDetails(payout_account);
  }, [payout_account]);

  const handleInputChange = async (property = '', value = '') => {
    setFormData((curr_data) => ({ ...curr_data, [property]: value }));
  };

  const submit = async () => {
    if (!form_data.bank || !form_data.name) return toast.error('Please fill all fields.');
    if (!form_data.number || form_data.number.length !== 10) {
      return toast.error('Account number must be 10 digit long');
    }
    const data = { payout_account: form_data };
    setSaving(true);
    const result = await updateUserData(user_data.id, { data });
    if (result) {
      toast.success('Update successful');
      dispatch(addDataToStore({ ...user_data, ...data }));
      setMode('view');
    }
    setSaving(false);
  };

  return (
    <>
      <BasicInfoSection
        title="Payout Account"
        icon={<Icon name="edit" onClick={() => setMode('edit')} />}
      >
        {mode === 'view' ? (
          <>
            <DetailItem title="Bank name">{account_details?.bank || 'N/A'}</DetailItem>
            <DetailItem title="Account name">{account_details?.name || 'N/A'}</DetailItem>
            <DetailItem title="Account Number">{account_details?.number || 'N/A'}</DetailItem>
          </>
        ) : (
          <>
            <DetailItem title="Bank name">
              <SimpleInput value={form_data.bank} onInput={(v) => handleInputChange('bank', v)} />
            </DetailItem>
            <DetailItem title="Account name">
              <SimpleInput value={form_data.name} onInput={(v) => handleInputChange('name', v)} />
            </DetailItem>
            <DetailItem title="Account Number">
              <SimpleInput
                value={form_data.number}
                onInput={(v) => handleInputChange('number', v)}
              />
            </DetailItem>
            <Spacer multiple={4} />
            <Button text="Save Changes" onClick={submit} loading={saving} />
            <Spacer multiple={4} />
            <Button text="Cancel" type="secondary" onClick={() => setMode('view')} />
          </>
        )}
      </BasicInfoSection>
    </>
  );
};
