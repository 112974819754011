import { Button } from 'app/shared/button';
import ContextMenu from 'app/shared/datatable/context-menu/context-menu';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import GmModal from 'app/shared/modal/modal';
import { Icon } from 'assets/icons';
import { PermissionsContext } from 'contexts/permissions';
import { useSubscriptionService } from 'hooks/sales/subscription';
import React, { useContext, useEffect, useState } from 'react';
import { IntegrationConfiguration } from '../configurations';
import styles from './item.module.css';
import { ModulePurchase } from './purchase';

export const IntegrationItem = ({ data }) => {
  const { permissions } = useContext(PermissionsContext);
  const { cancelSubscription, fetchSubscription } = useSubscriptionService();

  const [has_subscription, setHasSubscription] = useState(false);
  const [minimum_price, setMinimumPrice] = useState(0);
  const [show_purchase_modal, setShowPurchaseModal] = useState(false);
  const [show_config_modal, setShowConfigModal] = useState(false);
  const [show_confirmation, setConfirmation] = useState(false);
  const [subscription, setSubscription] = useState(null);

  const actions = [
    {
      label: 'Configure',
      value: 'configure',
      hidden: !permissions['integration:create'] || !data.can_be_configured
    }
  ];

  useEffect(() => {
    const sorted_pricing = (data.pricing || []).sort(
      (a, b) => a.contact_bracket.min - b.contact_bracket.min
    );

    const { prices } = sorted_pricing[0];
    setMinimumPrice(prices[0]);

    fetchSubscription({ query_string: `plan_code=${data.code}&status=active` }).then(
      ({ subscriptions: [sub] }) => {
        setSubscription(sub);
        setHasSubscription(sub && sub.id);
      }
    );
  }, [data.code]);

  const handleCancellation = async () => {
    const result = await cancelSubscription(subscription.id);
    if (result) {
      setSubscription(null);
      setHasSubscription(false);
    }
  };

  const handleConfiguration = (module_data = null) => {
    if (!module_data) return;
    setShowConfigModal(true);
  };

  const handleConfirmation = (permitted) => {
    if (permitted) handleCancellation();
    setConfirmation(() => false);
  };

  const handleMenuAction = (action) => {
    if (typeof action !== 'string') return;

    switch (action) {
      case 'configure':
        handleConfiguration(data);
        break;
      default:
    }
  };

  return (
    <>
      <div className={styles.card}>
        <div>
          <div className={styles.cardHeader}>
            <div className={styles.avatar}>
              <img src={data.avatar} alt={data.title} />
            </div>
            <span className={styles.bold}>{data.title}</span>
          </div>
          <div className={styles.cardBody}>
            <p>{data.description}</p>
          </div>
        </div>
        <div className={styles.extrasWrapper}>
          <div className={styles.amountDetails}>
            <div className={styles.amount}>
              <span>{minimum_price?.currency}</span>{' '}
              <span>{Number(minimum_price?.amount?.toFixed(2)).toLocaleString()}</span>
            </div>
            <div className={styles.duration}>&nbsp;/ month</div>
          </div>
          <div className={styles.actions}>
            {has_subscription ? (
              <Button
                text="Deactivate"
                className={styles.deactivateBtn}
                onClick={() => setConfirmation(true)}
                disabled={!permissions['integration:create']}
              />
            ) : (
              <Button
                text="Activate"
                className={styles.actionButton}
                onClick={() => setShowPurchaseModal(true)}
                disabled={!permissions['integration:create']}
              />
            )}

            <ContextMenu
              actions={actions.filter((action) => !action.hidden)}
              callback={handleMenuAction}
              text={<Icon name="expand_down" className={styles.moreBtn} />}
            />
          </div>
        </div>
      </div>
      <GmModal
        bodyClassName={styles.purchaseModal}
        show_title
        show_modal={show_purchase_modal}
        title={`Activate ${data.title} module`}
        onClose={() => setShowPurchaseModal(false)}
      >
        <ModulePurchase module_data={data} />
      </GmModal>
      <GmModal
        bodyClassName={styles.configModal}
        show_title
        show_modal={show_config_modal}
        title={`Configure ${data.title} module`}
        onClose={() => setShowConfigModal(false)}
      >
        <IntegrationConfiguration module_data={data} />
      </GmModal>
      <ConfirmationDialog
        title={`Deactivate ${data.title}?`}
        message={`Are you sure you want to deactivate your subscription? This action is not revocable.`}
        callback={handleConfirmation}
        is_open={show_confirmation}
      />
    </>
  );
};
