import React, { useEffect, useState } from 'react';
import { useReferralService } from 'hooks/iam/referrals';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { truncateText } from 'app/shared/utils/general';
import { toReadableNumber } from '@go-mailer/jarvis/lib/utilitiy/number';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export const AffiliateEarnings = () => {
  const {
    referral: { code }
  } = useSelector((store) => store.user_data);
  const { is_mobile_view } = useSelector((state) => state.metadata);

  const { fetchReferrals } = useReferralService();

  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [total_referrals, setTotalReferrals] = useState(0);

  useEffect(() => {
    handleDataRequest();
  }, []);

  const config = {
    actions: { single: () => [] },
    css: {},
    fields: [
      {
        title: 'Organisation Name',
        key: 'referred',
        formatter: (value) => (value ? value.name : 'N/A'),
        isTitle: true
      },
      {
        title: 'Earning',
        key: 'amount',
        formatter: (value) => toReadableNumber(value),
        isTitle: true
      },
      {
        title: 'Currency',
        key: 'payment',
        formatter: (value) => toReadableNumber(value?.currency || 'NGN'),
        isTitle: true
      },
      {
        title: 'Date earned',
        key: 'time_stamp',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => truncateText(value, 35) || 'N/A'
      }
    ],
    is_search_mode,
    items: Object.values(referrals).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    if (loading) return;
    setLoading(true);

    const { referrals, size, error } = await fetchReferrals({
      query_string: `code=${code}&sort_by=-created_on&page=${page}&population=${population}`
    });

    if (error) {
      toast.error('Unable to load referral data.');
      return;
    }

    setReferrals((curr_referrals) => ({
      ...curr_referrals,
      ...referrals.reduce((s, referral) => ({ ...s, [referral.id]: referral }), {})
    }));
    setTotalReferrals(() => size);
    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  return (
    <>
      <GridRow>
        <GridColumn span={4}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              action={() => {}}
              onClick={() => {}}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              showHeader
            />
          ) : (
            <WebDatatable
              config={{
                ...config,
                total_count: total_referrals
              }}
              action={() => {}}
              onClick={() => {}}
              checkbox
              loading_data={loading}
              table_actions={[]}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
            />
          )}
        </GridColumn>
      </GridRow>
    </>
  );
};
