import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { AffiliateAccountDetails } from 'app/member/affiliate/dashboard/account';
import { AffiliateEarnings } from 'app/member/affiliate/dashboard/earnings';
import { AffiliateProfileDetails } from 'app/member/affiliate/dashboard/profile';
import { AffiliateEarningsReport } from 'app/member/affiliate/dashboard/report';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';

export const RegularUserAffiliateModule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/settings' },
        { title: 'Affiliate Program', path: '/settings/affiliates' }
      ])
    );
  }, []);

  return (
    <>
      <GridRow num_of_columns={4}>
        <GridColumn>
          <AffiliateProfileDetails />
          <Spacer multiple={4} />
          <AffiliateAccountDetails />
        </GridColumn>
        <GridColumn span={3}>
          <AffiliateEarningsReport />
          <Spacer multiple={4} />
          <AffiliateEarnings />
        </GridColumn>
      </GridRow>
    </>
  );
};
