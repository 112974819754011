import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import React from 'react';
import { AffiliateAccountDetails } from './account';
import { AffiliateEarnings } from './earnings';
import { AffiliateProfileDetails } from './profile';
import { AffiliateEarningsReport } from './report';
import styles from './dashboard.module.css';

export const AffiliateDashboard = () => {
  return (
    <div className={styles.wrapper}>
      <GridRow num_of_columns={4}>
        <GridColumn>
          <AffiliateProfileDetails />
          <Spacer multiple={4} />
          <AffiliateAccountDetails />
        </GridColumn>
        <GridColumn span={3}>
          <AffiliateEarningsReport />
          <Spacer multiple={4} />
          <AffiliateEarnings />
        </GridColumn>
      </GridRow>
    </div>
  );
};
