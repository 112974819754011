import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import React from 'react';
import { AffiliateDashboard } from './dashboard';
import { AffiliateHeader } from './header';
import styles from './affiliate.module.css';
import useScreenSize from 'hooks/size';

export const AffiliateArea = () => {
  const { isTablet } = useScreenSize();
  return (
    <div className={styles.wrapper}>
      <AffiliateHeader />
      <Spacer multiple={8} />
      <GridRow num_of_columns={isTablet ? 6 : 10}>
        {!isTablet && <GridColumn span={2} />}
        <GridColumn span={6}>
          <AffiliateDashboard />
        </GridColumn>
        {!isTablet && <GridColumn span={2} />}
      </GridRow>
    </div>
  );
};
