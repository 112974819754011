import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { useReferralService } from 'hooks/iam/referrals';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const AffiliateEarningsReport = () => {
  const {
    referral: { code }
  } = useSelector((store) => store.user_data);
  const { fetchReferrals } = useReferralService();

  const [total_earning, setTotalEarnings] = useState();
  const [pending_earning, setPendingEarnings] = useState();

  const fetchTotalEarnings = async (total_earnings = 0, page = 0) => {
    const { referrals } = await fetchReferrals({
      query_string: `code=${code}&status=settled&return_only=amount&page=${page}&population=1000`
    });

    const sum = referrals.reduce((s, referral) => s + referral.amount, total_earnings);
    if (!referrals || !referrals.length) return sum;
    return await fetchTotalEarnings(sum, page + 1);
  };

  const fetchPendingEarnings = async () => {
    const { referrals } = await fetchReferrals({ query_string: `code=${code}&status=pending` });
    const sum = referrals.reduce((s, referral) => s + referral.amount, 0);
    return sum;
  };

  useEffect(() => {
    Promise.all([fetchPendingEarnings(), fetchTotalEarnings()]).then(([pending, total]) => {
      setTotalEarnings(total);
      setPendingEarnings(pending);
    });
  }, [code]);

  return (
    <>
      <SlidingStatBar>
        <PrimaryStatCard
          main_stat={{ label: 'Total Earnings', value: total_earning }}
          bottom_stat={{ label: '-', value: '-' }}
        />
        <PrimaryStatCard
          main_stat={{ label: 'Pending Earnings', value: pending_earning }}
          bottom_stat={{ label: '-', value: '-' }}
        />
      </SlidingStatBar>
    </>
  );
};
